<template>
  <b-card>
    <caixapostal-list
      id="caixapostalList"
      ref="caixapostalList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :filtros-rapidos="filtrosRapidos"
      ultima-rota="mensagem-view"
      filtro-de-holder="Data inicial de envio"
      filtro-para-holder="Data final de envio"
      filtro-de-para
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <div class="d-block">
          <b-badge
            v-if="props.row['nenhumaMsg']"
            variant="light-success"
          >
            Sem mensagens
          </b-badge>
          <b-badge
            v-else-if="props.row['qtdNaoLidas'] > 0"
            variant="light-warning"
          >
            {{ props.row['qtdNaoLidas'] }} {{ props.row['qtdNaoLidas'] > 1 ? 'não lidos' : ' não lido' }}
          </b-badge>
          <b-badge
            v-else
            variant="light-success"
          >
            Todas lidas
          </b-badge>
        </div>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <b-btn
          v-b-tooltip.hover
          title="Visualizar todas as mensagens"
          variant="none"
          class="btn btn-none rounded-pill mr-50"
          style="padding: 0.4rem;"
          :disabled="props.row.nenhumaMsg"
          @click="visualizarTodasMsg(props.row)"
        >
          <font-awesome-icon
            icon="fa-solid fa-envelopes-bulk"
            class="text-primary"
            size="lg"
          />
        </b-btn>
      </template>
    </caixapostal-list>
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px !important;
}

.data-width {
  width: 120px !important;
}
.caixapostal-td-acao {
  // min-width: 0.5rem;
  width: 12px;
}
</style>

<script>

import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'CaixaPostalVisualizacao',
  components: {
    CaixapostalList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
  },
  data() {
    return {
      botoesOption: null,
      linha: null,
      visualizacao: {
        placeholderBusca: 'Buscar por: CNPJ/CPF, Razão social ou Assunto',
        acaoClass: 'caixapostal-td-acao',
        disabledCheckbox: true,
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'Assunto', 'ConsultadoEm'], valor: null }],
      filtrosRapidos: [
        {
          id: 'Pronampe',
          colunas: ['TipoMensagem'],
          label: 'Pronampe',
          valor: '2',
        },
        {
          id: 'MalhaFiscal',
          colunas: ['TipoMensagem'],
          label: 'Malha fiscal',
          valor: '1',
        },
        {
          id: 'TermosReceita',
          colunas: ['TipoMensagem'],
          label: 'Termos da receita',
          valor: '3',
        },
        {
          id: 'Lidas',
          colunas: ['Lidas'],
          label: 'Lidas',
          valor: true,
        },
        {
          id: 'NaoLidas',
          colunas: ['NaoLidas'],
          label: 'Não lidas',
          valor: false,
        },
        {
          id: 'Relevante',
          colunas: ['Relevante'],
          label: 'Relevantes',
          valor: true,
        },
        {
          id: 'RelevanteNaoLido',
          colunas: ['RelevanteNaoLido'],
          label: 'Relevantes Não Lidas',
          valor: true,
        },
        {
          id: 'SemMensagem',
          colunas: ['Assunto'],
          label: 'Sem mensagens',
          valor: 'Contribuinte não possui mensagens em sua caixa postal.',
        },
        {
          id: 'EProcesso',
          colunas: ['EProcesso'],
          label: 'e-Processos',
          valor: true,
        },
        {
          id: 'EProcessoNaoLida',
          colunas: ['EProcessoNaoLida'],
          label: 'e-Processos não lidas',
          valor: true,
        }],
    }
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.colunas[0].evento = this.visualizarTodasMsg
    this.visualizacao.evento = this.carregar
  },
  mounted() {
    this.aplicarFiltroQuery()
  },
  updated() {
    this.aplicarFiltroQuery()
  },
  methods: {
    /* eslint-disable no-param-reassign */
    carregar(pagina, tamanhoPagina, filtros) {
      return api.getPaginado(pagina, tamanhoPagina, filtros)
    },
    visualizarTodasMsg(row) {
      if (!row.nenhumaMsg) {
        this.$router.push({
          name: 'mensagem-view',
          query: {
            filtroRapido: this.$route.query.filtroRapido ? this.$route.query.filtroRapido : '',
            dataInicio: this.$route.query.dataInicio ? this.$route.query.dataInicio : '',
            dataFim: this.$route.query.dataFim ? this.$route.query.dataFim : '',
          },
          params: { id: row.id, nome: row.razaoSocial },
        })
      }
    },
    aplicarFiltroQuery() {
      this.$nextTick(() => {
        const params = this.$route.query
        if (params && this.$refs.caixapostalList) {
          const filtroRapido = params.filtroRapido
            ? this.filtrosRapidos.find(f => f.id === params.filtroRapido)
            : null
          this.$refs.caixapostalList.aplicarFiltro(params.busca, filtroRapido)
        }
      })
    },
  },
}
</script>
