export default [{
  label: 'Razão social', field: 'razaoSocial', editable: true, evento: null,
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal' },
{
  label: 'Lidos/Não lidos', field: 'qtdNaoLidas', type: 'custom',
},
{
  label: 'Consultado em', field: 'consultadoEm',
}]
